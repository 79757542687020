import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueNumberFormat from "vue-number-format";
import VCalendar from "v-calendar";
import VueAppend from "vue-append";
import "v-calendar/style.css";
import VueCookies from "vue3-cookies";
import mitt from "mitt"; // Import mitt
import { createHead } from "@vueuse/head";
import VueSocialSharing from "vue-social-sharing";
import VueSocialChat from "vue-social-chat";
import "vue-social-chat/dist/style.css";
import { install } from "vue3-recaptcha-v2";
import vue3GoogleLogin from "vue3-google-login";

/*import { vsDialog, vsButton } from "vuesax3";
import "vuesax3/dist/vuesax.css";*/
//const emitter = mitt();
//Vue.prototype.$api_location = "https://www.kaitorasap.co.th/api-vue/index.php/";
const eventBus = mitt();
const head = createHead();
const app = createApp(App);
app.provide("eventBus", eventBus);
app.use(router);
app.use(VCalendar);
app.use(VueAppend);
app.use(VueCookies);
app.use(head);
app.use(VueSocialSharing);
app.use(VueSocialChat);
app.use(vue3GoogleLogin, {
  clientId:
    "510250614916-usepmqgrde6spce78c62nqv3o3ece917.apps.googleusercontent.com",
  scope: "email profile openid",
});
app.use(install, {
  sitekey: "6Lc5CTEqAAAAABRA6hDOI2dS2rVMZXT1ZdJFJLb9",
  cnDomains: false, // Optional, If you use in China, set this value true
});

/*app.use(vsDialog);
app.use(vsButton);*/
app.use(VueNumberFormat, {
  prefix: "",
  decimal: ".",
  thousand: ",",
  precision: 0,
  acceptNegative: false,
});
app.use(VueCookies, {
  expireTimes: "1d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});
app.mount("#app");
//createApp(App).use(router).mount("#app");
